import { graphql, useStaticQuery } from 'gatsby';

const useLegalPages = () => {
  const { allDatoCmsLegalPage } = useStaticQuery(legalPagesQuery);

  return allDatoCmsLegalPage?.nodes;
};

export default useLegalPages;

const legalPagesQuery = graphql`
  query legalPagesQuery {
    allDatoCmsLegalPage(sort: { fields: pageOrder, order: ASC }) {
      nodes {
        ...datoCmsLegalPage
      }
    }
  }
`;
